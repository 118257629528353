var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.refillListFormatted.length && _vm.loading)?_c('v-card',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text-field, table, data-table"}})],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"font-weight-semibold text-uppercase"},[_vm._v(" Liste des recharges encours ")]),_c('v-divider'),_c('br'),_c('div',{staticClass:"mx-auto"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icon.mdiMagnify,"label":"Rechercher","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink mx-auto",attrs:{"id":"list-refill","headers":_vm.headers,"items":_vm.refillListFormatted,"search":_vm.search,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":true,"divider":true},scopedSlots:_vm._u([{key:"item.num",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center p-5"},[_c('div',{staticClass:"d-flex flex-column ms-3 "},[_c('span',{staticClass:"font-weight-semibold text-body-1 text--primary"},[_vm._v(" N° "+_vm._s(item.num)+" ")]),_c('span',{staticClass:"custom-text--small"},[_vm._v("Envoyé le : "+_vm._s(item.open_date))]),(item.close_date)?_c('span',{staticClass:"custom-text--small custom-red--text"},[_vm._v("Fermé le : "+_vm._s(item.close_date))]):_vm._e()])])]}},{key:"item.type_depot",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text-truncate text--primary"},[_vm._v("Type: "),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.type_depot === _vm.DEPOT ?'Versement':'Virement'))])]),_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(" "+_vm._s(item.moyen_paiement)+" ")])])])]}},{key:"item.account_debite",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v("Débiteur: "),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.account_debite===null?'N/A':item.account_debite))])]),_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--secondary"},[_vm._v("Créditeur: "),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.account_credite===null?'N/A':item.account_credite))])])])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text-truncate text--primary"},[_vm._v("Montant: "),_c('span',{staticClass:"font-weight-semibold success--text"},[_vm._v(" "+_vm._s(_vm.withCurrency(item.amount)))])]),_c('span',{staticClass:"d-block text-truncate text--primary"},[_vm._v("Reférence: "),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.reference))])]),(item.file_name !=='0')?_c('span',{staticClass:"d-block text-truncate text--primary"},[_vm._v("Reçu: "),_c('a',{staticClass:"text-decoration-none custom--info",attrs:{"href":item.file_url,"target":"_blank"}},[_c('v-icon',{staticClass:"custom--icon"},[_vm._v(_vm._s(_vm.icon.mdiFileDocumentOutline))]),_vm._v(" "+_vm._s(item.file_name))],1)]):_vm._e()])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[(item.state===1)?_c('v-chip',{staticClass:"ma-2 text-center pr-5 pl-5",attrs:{"color":"#F1FFE8FF"}},[_c('b',{staticClass:"success--text"},[_vm._v("Traité")])]):(item.state===0)?_c('v-chip',{staticClass:"ma-2 text-center",attrs:{"color":"#FFF6ECFF"}},[_c('b',{staticClass:"custom-warning--text"},[_vm._v("En cours")])]):_c('v-chip',{staticClass:"ma-2 text-center",attrs:{"color":"#FFECECFF"}},[_c('b',{staticClass:"error--text"},[_vm._v("Rejeté")])])],1)])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }